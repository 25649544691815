const Profile_Line_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='269.244'
			height='309.82'
			viewBox='0 0 269.244 309.82'
			fill='none'

			{...props}
		>
			<path
				d='m18,309.82c0-64.409,52.214-116.622,116.622-116.622s116.622,52.214,116.622,116.622'
				strokeMiterlimit='10'
				strokeWidth='36'
			/>
			<path
				d='m70.277,72.992c-1.955,24.01,9.727,46.99,30.028,58.893,21.732,12.743,50.193,10.947,69.34-5.291,20.803-17.642,20.443-43.708,20.329-47.435-.734-24.051-16.238-51.187-40.659-56.47-12.264-2.653-31.777-.877-40.659,12.988-7.411,11.569-5.638,28.774,2.823,38.4,17.858,20.314,70.935,13.044,97.929-17.471C227.727,35.899,229.001,10.18,228.939.1'
				strokeMiterlimit='10'
				strokeWidth='33'
			/>
		</svg>
	)
}

export default Profile_Line_SVG
