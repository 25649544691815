const Logo_Small_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='89.315'
			height='18.597'
			viewBox='0 0 89.315 18.597'

			fill='#3c4156'

			{...props}
		>
			<defs>
				<clipPath id='clip-path'>
					<rect
						id='Rectangle_27'
						data-name='Rectangle 27'
						width='89.315'
						height='18.597'
					/>
				</clipPath>
			</defs>
			<g
				id='Group_3'
				data-name='Group 3'
				clipPath='url(#clip-path)'
			>
				<path
					id='Path_4'
					data-name='Path 4'
					d='M5.091,37.589a3.24,3.24,0,0,1-3.24-3.24v-9.46H4.8V34.1a1.153,1.153,0,0,0,1.154,1.154h.321v2.331Z'
					transform='translate(-1.435 -19.291)'
				/>
				<path
					id='Path_5'
					data-name='Path 5'
					d='M1.881,3.762a1.982,1.982,0,0,1-.321-.027A1.881,1.881,0,0,1,1.881,0,1.959,1.959,0,0,1,2.2.027a1.881,1.881,0,0,1-.32,3.735Z'
					transform='translate(0 0)'
				/>
				<path
					id='Path_6'
					data-name='Path 6'
					d='M163.6,25.08c-2.745,0-2.745,1.747-2.745,1.884,0,.445,0,1.831,2.745,1.831a7.447,7.447,0,0,0,2.977-.635s-.289-3.08-2.977-3.08m-1.185,11.046c-4.6,0-6.742-3.5-6.851-7.044h2.689c.126,2.173,1.311,4.578,4.293,4.578a3.667,3.667,0,0,0,3.742-2.866,14.7,14.7,0,0,1-2.918.421c-4.766,0-4.98-3.707-4.98-4.252,0-2.18,1.4-4.349,5.21-4.349,4.087,0,5.275,4.184,5.275,4.184a4.493,4.493,0,0,0,1.392-3.673h3.56a1.487,1.487,0,0,1,.438.565s.754-1.076,3.337-1.076c3.375,0,4.1,1.629,4.1,1.629s.788-1.629,4.1-1.629a5.577,5.577,0,0,1,5.571,5.571v7.64h-2.951v-7.64a2.619,2.619,0,1,0-5.239,0v7.64h-2.951v-7.64A2.609,2.609,0,0,0,178.44,25.7a2.809,2.809,0,0,0-.832-.129,2.622,2.622,0,0,0-2.619,2.619v7.64h-2.951V26.291a6.682,6.682,0,0,1-2.727,3.591c0,3.779-2.681,6.244-6.891,6.244'
					transform='translate(-120.58 -17.529)'
				/>
				<path
					id='Path_7'
					data-name='Path 7'
					d='M96.39,36.126c-4.251,0-6.892-2.588-6.892-6.755,0-4.1,2.706-6.755,6.893-6.755a6.426,6.426,0,0,1,5.162,2.108,5.21,5.21,0,0,1,.966,4.359H99.2a5.168,5.168,0,0,0,.211-1.307,2.805,2.805,0,0,0-3.15-2.695c-2.159,0-3.448,1.548-3.448,4.14,0,2.746,1.23,4.44,3.374,4.44a3.331,3.331,0,0,0,3.12-1.745h3.54c-.254,1.533-2.321,4.211-6.455,4.211'
					transform='translate(-69.369 -17.529)'
				/>
				<path
					id='Path_8'
					data-name='Path 8'
					d='M328.847,25.08a3.044,3.044,0,0,0-3.361,3h6.508s.006-.311,0-.421c-.1-2.037-2.027-2.581-3.147-2.581m.131,11.046c-4.251,0-6.892-2.588-6.892-6.755,0-4.1,2.706-6.755,6.892-6.755a6.188,6.188,0,0,1,5.076,2.062,6.368,6.368,0,0,1,.991,5.241h-9.559c0,.2.108,3.743,3.286,3.743a3.34,3.34,0,0,0,3.12-1.745h3.541c-.254,1.533-2.321,4.21-6.455,4.21'
					transform='translate(-249.646 -17.529)'
				/>
				<path
					id='Path_9'
					data-name='Path 9'
					d='M35.511,35.827V28.253A2.777,2.777,0,0,0,33.319,25.6a2.746,2.746,0,0,0-.428-.034,2.622,2.622,0,0,0-2.619,2.619v7.64H27.32V28.112a10.976,10.976,0,0,0-1.385-4.985h3.181a1.914,1.914,0,0,1,.454.636,4.517,4.517,0,0,1,3.322-1.147,5.577,5.577,0,0,1,5.571,5.571v7.64Z'
					transform='translate(-20.102 -17.53)'
				/>
				<path
					id='Path_10'
					data-name='Path 10'
					d='M389.193,74.4a.814.814,0,0,1-.292.291.786.786,0,0,1-.407.108.778.778,0,0,1-.4-.108.815.815,0,0,1-.29-.291.811.811,0,0,1,0-.81.815.815,0,0,1,.29-.291.778.778,0,0,1,.4-.108.786.786,0,0,1,.407.108.815.815,0,0,1,.292.291.816.816,0,0,1,0,.81m.311-.829a1.105,1.105,0,0,0-.584-.585,1.1,1.1,0,0,0-.849,0,1.112,1.112,0,0,0-.349.236,1.1,1.1,0,0,0-.235.349,1.1,1.1,0,0,0,0,.848,1.1,1.1,0,0,0,.235.349,1.112,1.112,0,0,0,.349.236,1.056,1.056,0,0,0,.424.085,1.07,1.07,0,0,0,.427-.085,1.1,1.1,0,0,0,.583-.585,1.1,1.1,0,0,0,0-.848'
					transform='translate(-300.273 -56.508)'
				/>
				<path
					id='Path_11'
					data-name='Path 11'
					d='M390.766,75.671h.165a.153.153,0,0,1,.1.033.126.126,0,0,1,.042.106.138.138,0,0,1-.041.111.147.147,0,0,1-.1.036h-.164Zm.531.323a.327.327,0,0,0,.05-.183.322.322,0,0,0-.048-.179.317.317,0,0,0-.127-.114A.367.367,0,0,0,391,75.48H390.5v1.008h.263v-.34h.167l.157.34h.289l-.194-.391a.317.317,0,0,0,.112-.1'
					transform='translate(-302.677 -58.504)'
				/>
			</g>
		</svg>
	)
}

export default Logo_Small_SVG
