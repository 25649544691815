const Logo_Birth_SVG = ({...props}) => {
	return (
			<svg width="297" height="62" viewBox="0 0 297 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<g clipPath="url(#clip0_545_18)">
					<mask id="mask0_545_18"
					      style={{maskType: 'luminance'}}
					      maskUnits="userSpaceOnUse" x="0" y="0" width="297" height="62">
						<path d="M296.971 0H0V61.834H296.971V0Z" fill="white"/>
					</mask>
					<g mask="url(#mask0_545_18)">
						<path d="M12.1568 60.8393C9.29985 60.8393 6.55995 59.7044 4.53981 57.6843C2.51967 55.6641 1.38477 52.9242 1.38477 50.0673V18.6113H11.1978V49.2543C11.1978 49.758 11.297 50.2568 11.4898 50.7221C11.6826 51.1875 11.9651 51.6103 12.3214 51.9664C12.6776 52.3226 13.1005 52.605 13.5659 52.7977C14.0313 52.9904 14.5301 53.0895 15.0338 53.0893H16.0998V60.8383L12.1568 60.8393Z" fill="#3A4156"/>
						<path d="M6.25503 12.508C5.8979 12.5076 5.54139 12.4782 5.18903 12.42C3.64598 12.1524 2.25942 11.3158 1.30327 10.0755C0.347132 8.83518 -0.109041 7.28136 0.024882 5.72102C0.158805 4.16068 0.873082 2.70732 2.02659 1.64807C3.18011 0.588821 4.68895 0.000735831 6.25503 0C6.61217 5.62757e-05 6.96871 0.0294888 7.32103 0.088C8.86407 0.35559 10.2506 1.19219 11.2068 2.4325C12.1629 3.67282 12.6191 5.22664 12.4852 6.78698C12.3512 8.34732 11.637 9.80068 10.4835 10.8599C9.32995 11.9192 7.8211 12.5073 6.25503 12.508Z" fill="#EB5E2E"/>
						<path d="M143.055 25.1082C133.927 25.1082 133.927 30.9152 133.927 31.3722C133.927 32.8502 133.927 37.4612 143.055 37.4612C146.465 37.4461 149.836 36.7268 152.955 35.3482C152.955 35.3482 151.993 25.1082 143.055 25.1082ZM139.113 61.8342C123.813 61.8342 116.695 50.1912 116.334 38.4142H125.274C125.694 45.6392 129.634 53.6372 139.548 53.6372C149.662 53.6372 152.234 44.4972 151.99 44.1082C148.812 44.8987 145.562 45.3677 142.29 45.5082C126.444 45.5082 125.733 33.1832 125.733 31.3712C125.733 24.1212 130.387 16.9102 143.057 16.9102C156.648 16.9102 160.597 30.8232 160.597 30.8232C160.597 30.8232 165.227 27.4632 165.227 18.6102H177.067C177.698 19.1087 178.198 19.7536 178.524 20.4892C178.524 20.4892 181.03 16.9102 189.624 16.9102C200.846 16.9102 203.241 22.3262 203.241 22.3262C203.241 22.3262 205.86 16.9102 216.857 16.9102C221.768 16.9157 226.477 18.8691 229.949 22.3418C233.422 25.8146 235.375 30.523 235.381 35.4342V60.8342H225.568V35.4342C225.543 33.1404 224.615 30.949 222.984 29.3357C221.354 27.7224 219.152 26.8175 216.858 26.8175C214.565 26.8175 212.363 27.7224 210.733 29.3357C209.102 30.949 208.174 33.1404 208.149 35.4342V60.8342H198.336V35.4342C198.346 33.6029 197.777 31.8154 196.709 30.3277C195.641 28.8399 194.13 27.7283 192.392 27.1522C191.496 26.8713 190.564 26.727 189.625 26.7242C187.316 26.7265 185.102 27.645 183.469 29.2779C181.836 30.9108 180.917 33.1249 180.915 35.4342V60.8342H171.102V29.1322C171.102 29.1322 168.485 37.7202 162.033 41.0732C162.033 53.6372 153.118 61.8342 139.12 61.8342" fill="#3A4156"/>
						<path d="M89.8447 61.8332C75.7087 61.8332 66.9277 53.2272 66.9277 39.3722C66.9277 25.7282 75.9277 16.9102 89.8457 16.9102C97.2697 16.9102 103.366 19.3992 107.01 23.9192C108.585 25.9462 109.691 28.2981 110.246 30.8049C110.801 33.3116 110.792 35.9104 110.22 38.4132H99.1817C99.6005 37.0002 99.8358 35.5392 99.8817 34.0662C99.8817 30.9662 97.6927 25.1072 89.4097 25.1072C82.2297 25.1072 77.9437 30.2532 77.9437 38.8742C77.9437 48.0052 82.0317 53.6362 89.1617 53.6362C96.0617 53.6362 98.7867 49.2512 99.5357 47.8362H111.308C110.464 52.9362 103.591 61.8362 89.8457 61.8362" fill="#3A4156"/>
						<path d="M263.337 25.1072C257.898 25.1072 253.473 27.5962 252.318 33.4072C252.219 33.9615 252.167 34.5231 252.163 35.0862H273.801C273.801 35.0862 273.82 34.0522 273.801 33.6862C273.452 26.9122 267.059 25.1052 263.337 25.1052M263.771 61.8332C249.635 61.8332 240.854 53.2272 240.854 39.3722C240.854 25.7282 249.854 16.9102 263.771 16.9102C271.257 16.9102 277.092 19.2812 280.648 23.7652C284.128 28.1552 285.337 34.7122 283.943 41.1902H252.163C252.163 41.8492 252.521 53.6362 263.089 53.6362C269.95 53.6362 272.689 49.2812 273.462 47.8362H285.234C284.389 52.9362 277.518 61.8362 263.773 61.8362" fill="#3A4156"/>
						<path d="M51.2335 60.8391V35.6531C51.1857 33.5788 50.4408 31.581 49.1188 29.9818C47.7968 28.3826 45.9748 27.2752 43.9465 26.8381C43.4762 26.7634 43.0008 26.7256 42.5245 26.7251C40.2152 26.7275 38.0012 27.6459 36.3683 29.2788C34.7353 30.9118 33.8169 33.1258 33.8145 35.4351V60.8351H24.0005V35.1871C23.9835 34.7121 23.5555 24.5371 19.3945 18.6121H29.9695C30.6126 19.2059 31.1263 19.9259 31.4785 20.7271C31.4785 20.7271 34.8785 16.9121 42.5235 16.9121C47.4346 16.9174 52.143 18.8706 55.6158 22.3432C59.0885 25.8157 61.042 30.524 61.0475 35.4351V60.8351L51.2335 60.8391Z" fill="#3A4156"/>
						<path d="M295.656 59.5036C295.421 59.904 295.088 60.2373 294.687 60.4716C294.277 60.7126 293.809 60.8371 293.333 60.8316C292.861 60.8366 292.396 60.7121 291.989 60.4716C291.59 60.2367 291.258 59.9035 291.024 59.5036C290.788 59.0939 290.664 58.6294 290.664 58.1566C290.664 57.6838 290.788 57.2194 291.024 56.8096C291.258 56.4098 291.59 56.0765 291.989 55.8416C292.396 55.6011 292.861 55.4766 293.333 55.4816C293.809 55.4762 294.277 55.6007 294.687 55.8416C295.088 56.076 295.421 56.4093 295.656 56.8096C295.891 57.2198 296.014 57.6841 296.014 58.1566C296.014 58.6292 295.891 59.0935 295.656 59.5036ZM296.689 56.7476C296.318 55.873 295.622 55.1763 294.748 54.8036C294.301 54.6161 293.821 54.5195 293.336 54.5195C292.851 54.5195 292.371 54.6161 291.924 54.8036C291.491 54.9888 291.097 55.2548 290.764 55.5876C290.431 55.9203 290.166 56.3142 289.983 56.7476C289.797 57.1942 289.701 57.6733 289.701 58.1571C289.701 58.641 289.797 59.12 289.983 59.5666C290.166 59.9997 290.431 60.3933 290.764 60.7256C291.097 61.0584 291.491 61.3245 291.924 61.5096C292.369 61.7004 292.849 61.7971 293.333 61.7936C293.821 61.7972 294.304 61.7006 294.752 61.5096C295.626 61.1383 296.321 60.441 296.69 59.5656C296.876 59.119 296.972 58.64 296.972 58.1561C296.972 57.6723 296.876 57.1932 296.69 56.7466" fill="#EB5E2E"/>
						<path d="M292.897 57.0801H293.447C293.563 57.0789 293.677 57.1177 293.769 57.1901C293.818 57.2337 293.857 57.2884 293.881 57.3497C293.906 57.4111 293.916 57.4774 293.91 57.5432C293.915 57.611 293.906 57.6794 293.882 57.7432C293.858 57.807 293.82 57.8647 293.772 57.9121C293.68 57.9914 293.562 58.0338 293.441 58.0312H292.897V57.0801ZM294.663 58.1522C294.777 57.97 294.834 57.758 294.828 57.5432C294.835 57.3318 294.78 57.1231 294.669 56.9432C294.565 56.7811 294.419 56.6504 294.247 56.5651C294.066 56.4763 293.867 56.4334 293.666 56.4401H292.018V59.7902H292.894V58.6631H293.449L293.97 59.7942H294.932L294.287 58.4941C294.437 58.4125 294.565 58.2954 294.66 58.1532" fill="#EB5E2E"/>
					</g>
				</g>
				<defs>
					<clipPath id="clip0_545_18">
						<rect width="296.971" height="61.834" fill="white"/>
					</clipPath>
				</defs>
			</svg>
	)
}
export default Logo_Birth_SVG;