const MenuLine_Nos_Metiers_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='95.715'
			height='17.192'
			viewBox='0 0 95.715 17.192'
			stroke='#d36f3b'

			{...props}
		>
			<path
				id='Scribble-Nos-métiers'
				d='M-19942.807-8302.219s8.326-9.94,18.107-8.91,11.59,3.138,11.969,6.966-2.617,6.041-5.982,5.79-6.162-2.279-4.527-5.79,4.1-5.369,22.48-6.966,51.867,2.932,51.867,2.932'
				transform='translate(19944.34 8313.543)'
				fill='none'
				strokeWidth='4'
			/>
		</svg>
	)
}

export default MenuLine_Nos_Metiers_SVG
