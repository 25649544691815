const MenuLine_Contact_Devis_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='134.391'
			height='17.193'
			viewBox='0 0 134.391 17.193'
			stroke='#d36f3b'

			{...props}
		>
			<path
				id='Scribble-Contact-_-devis'
				data-name='Scribble-Contact-&amp;-devis'
				d='M-19971.578-8308.2s37.1-3.962,46.879-2.932,11.59,3.138,11.969,6.966-2.617,6.041-5.982,5.79-6.162-2.279-4.527-5.79,4.1-5.369,22.48-6.966,63.158,2.932,63.158,2.932'
				transform='translate(19971.791 8313.544)'
				fill='none'
				strokeWidth='4'
			/>
		</svg>
	)
}

export default MenuLine_Contact_Devis_SVG
