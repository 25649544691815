const MenuLine_Home_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='78.852'
			height='12.594'
			viewBox='0 0 78.852 12.594'

			stroke='#d36f3b'

			{...props}
		>
			<path
				id='Scribble-Accueil'
				d='M-20061.6-8300.107s21.617-8.939,45.375-8.741,30.512,7.948,31.227,8.741'
				transform='translate(20062.365 8310.853)'
				fill='none'
				strokeWidth='4'
			/>
		</svg>
	)
}

export default MenuLine_Home_SVG
