import styles from '../styles/components/Header.module.sass';
import Logo_SVG from './svg/Logo_SVG';
import Link from 'next/link';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger';
import MenuLine_Home_SVG from './svg/lines/menu_lines/MenuLine_Home_SVG';
import MenuLine_Nos_Metiers_SVG from './svg/lines/menu_lines/MenuLine_Nos_Metiers_SVG';
import MenuLine_Qui_Sommes_Nous_SVG from './svg/lines/menu_lines/MenuLine_Qui_Sommes_Nous_SVG';
import MenuLine_Contact_Devis_SVG from './svg/lines/menu_lines/MenuLine_Contact_Devis_SVG';
import useUpdateEffect from '../utils/useUpdateEffect';
import gsap, * as GSAP from 'gsap/dist/gsap';
import {DrawSVGPlugin} from 'gsap/dist/DrawSVGPlugin';
import {useRouter} from 'next/router';
import Logo_Small_SVG from './svg/Logo_Small_SVG';
import {FaBars, FaChevronDown, FaTimes} from 'react-icons/fa';
import Profile_Line_SVG from './svg/icons/Profile_Line_SVG';
import MenuLine_Notre_Actualite_SVG from "./svg/lines/menu_lines/MenuLine_Notre_Actualite_SVG";
import Logo_Birth_SVG from "./svg/Logo_Birth_SVG";
import Logo_Birth_Mobile_SVG from "./svg/Logo_Birth_Mobile_SVG";

if (typeof window
    !== 'undefined') {
    gsap.registerPlugin(DrawSVGPlugin);
}

const MenuItem = ({
                      menu_item,
                      smallScreenSize,
                  }) => {

    const [hovered, setHovered] = useState(false);
    const [lineTimeline, setLineTimeline] = useState(null);

    const router = useRouter();

    //region Handlers
    useEffect(
        () => {
            setLineTimeline(gsap.timeline({
                defaults: {
                    duration: .5,
                },
            })
                .fromTo(
                    `.menu_item#${menu_item.id} .line svg path`,
                    {
                        drawSVG: 0,
                    },
                    {
                        drawSVG: true,
                    },
                )
                .pause());
        },
        [menu_item.id],
    );

    useUpdateEffect(
        () => {
            if (hovered) {
                lineTimeline.play();
            } else {
                lineTimeline.reverse();
            }
        },
        [hovered],
    );

    return (
        <div
            className={cn(
                styles.menu_item_container,
            )}
        >
            <Link
                scroll={false}
                href={menu_item.link}
                className={cn(
                    styles.menu_item,
                    'menu_item',
                    {
                        [styles.active]: router.asPath.split('#')[0]
                        === menu_item.link,
                    },
                )}
                id={menu_item.id}

                onPointerEnter={() => {
                    setHovered(true);
                }}

                onPointerLeave={() => {
                    setHovered(false);
                }}
            >
                <div className={styles.title}>
                    <span className={styles.text}>{menu_item.title}</span>
                </div>

                <div
                    className={cn(
                        styles.line,
                        'line',
                    )}
                >
                    {menu_item.line}
                </div>
            </Link>
        </div>
    );
};

const Header = ({firstLoad = false}) => {

    const [smallScreenSize, setSmallScreenSize] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);

    const defaultHeaderContainerRef = useRef();

    const changeWindowSize = () => {
        let windowSize = window.innerWidth;
        setSmallScreenSize(windowSize
            <= 1024);
    };

    //region Menu
    const menu = useMemo(
        () => {
            return [
                {

                    title: 'Métiers',
                    link: '/nos-metiers',
                    id: 'nos_metiers',
                    line: <MenuLine_Nos_Metiers_SVG/>,
                },
                {
                    title: 'Équipe',
                    link: '/qui-sommes-nous',
                    id: 'qui_sommes_nous',
                    line: <MenuLine_Home_SVG/>,
                },
                {
                    title: 'Actualités',
                    link: 'https://actualites.income.ec/',
                    id: 'notre_actualite',
                    line: <MenuLine_Contact_Devis_SVG/>,
                },
                {
                    title: 'Recrutement',
                    link: '/nous-rejoindre',
                    id: 'accueil',
                    line: <MenuLine_Qui_Sommes_Nous_SVG/>,
                },
                {
                    title: 'Contact',
                    link: '/contact',
                    id: 'contact_devis',
                    line: <MenuLine_Notre_Actualite_SVG/>,
                },
            ];
        },
        [],
    );
    //endregion

    useEffect(
        () => {
            //region Pin header
            const pinHeader = ScrollTrigger.create({
                markers: false,
                trigger: 'body',
                start: '1px top',
                end: 'max',

                pin: '.header_container',
                pinSpacing: false,
            });

            const showAnim = gsap.fromTo(
                '.header',
                {
                    yPercent: -100,
                    paused: true,
                    duration: .3,
                },
                {
                    yPercent: 0,
                    delay: firstLoad
                        ? 3
                        + .2
                        : .5
                        + .2,
                },
            );

            const hideShowScrollTrigger = ScrollTrigger.create({
                markers: false,
                start: 'top top',
                end: 'max',
                onUpdate: (self) => {
                    self.direction
                    === -1
                        ? showAnim.play()
                        : showAnim.reverse();
                },
            });
            //endregion

            //region Window resize
            changeWindowSize();
            window.addEventListener(
                'resize',
                () => {
                    changeWindowSize();
                },
            );
            //endregion

            const profileLineTimeline = gsap.timeline();
            profileLineTimeline.fromTo(
                '.profile_line path',
                {drawSVG: '0'},
                {
                    drawSVG: '100%',
                    stagger: .2,
                    duration: .5,
                    ease: GSAP.Power0.easeNone,
                },
                firstLoad
                    ? 3
                    + .2
                    : .5
                    + .2,
            );

            return () => {
                pinHeader?.kill();
                hideShowScrollTrigger?.kill();
            };
        },
        [],
    );

    useEffect(
        () => {
            if (smallScreenSize
                && menuOpened) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
        [
            smallScreenSize,
            menuOpened,
        ],
    );

    return (
        <header
            className={cn(
                styles.header_container,
                'header_container',
            )}
        >
            <div
                className={cn(
                    styles.header,
                    'header',
                )}
            >
                {smallScreenSize
                    && <div className={styles.small_screen_header}>

                        <Link
                            scroll={false}
                            href={'/'}
                            className={styles.logo_container}
                            aria-label={`Retour à la page d'accueil`}
                        >
                            <Logo_Birth_SVG className={styles.logo_small}/>
                        </Link>

                        <div
                            className={styles.menu_handler}
                            onClick={() => setMenuOpened(!menuOpened)}
                        >
                            <FaBars/>
                        </div>
                    </div>}

                <div className={styles.default_header_container_overflow}>
                    <div
                        className={cn(
                            styles.default_header_container,
                            {[styles.mobile_shown]: menuOpened},
                        )}
                        ref={defaultHeaderContainerRef}
                        onClick={(e) => {
                            if (e.target
                                === defaultHeaderContainerRef.current) {
                                setMenuOpened(false);
                            }
                        }}
                    >
                        <div className={styles.default_header}>
                            {/*region Logo*/}
                            <Link
                                scroll={false}
                                href={'/'}
                                className={styles.logo_container}
                                aria-label={`Retour à la page d'accueil`}
                            >
                                {smallScreenSize
                                    ? <Logo_Birth_SVG className={styles.logo_small}/>
                                    : <Logo_Birth_SVG className={styles.logo}/>}
                            </Link>
                            {/*endregion*/}

                            {/*region Right*/}
                            <div className={styles.right}>
                                {/*region Menu*/}
                                <div className={styles.menu}>
                                    {menu.map(menu_item => <MenuItem
                                        menu_item={menu_item}
                                        key={menu_item.id}
                                        smallScreenSize={smallScreenSize}
                                    />)}
                                </div>
                                {/*endregion*/}

                                {/*region Our ecosystem*/}
                                <Link
                                    scroll={false}
                                    href={'/notre-ecosysteme'}
                                    className={styles.our_ecosystem}
                                >
                                    <Profile_Line_SVG className={'profile_line'}/>
                                    <span className={styles.text}>Notre écosystème</span>
                                </Link>
                                {/*endregion*/}
                            </div>
                            {/*endregion*/}

                            {smallScreenSize
                                && <div className={styles.close_menu}>
					 <span
                         onClick={() => setMenuOpened(false)}
                     >Fermer menu</span>
                                    <div
                                        className={styles.icon}
                                        onClick={() => setMenuOpened(false)}
                                    >
                                        <FaTimes/>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
