const MenuLine_Notre_Actualite_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='158.221'
			height='12.704'
			viewBox='0 0 158.221 12.704'
			stroke='#d36f3b'
			
			{...props}
		>
			<path
				id='Scribble-notre-actualité'
				d='M-20061.6-8300.107s44.271-8.939,92.922-8.741,62.486,7.948,63.949,8.741'
				transform='translate(20061.998 8310.852)'
				fill='none'
				strokeWidth='4'
			/>
		</svg>
	)
}

export default MenuLine_Notre_Actualite_SVG
