const MenuLine_Qui_Sommes_Nous_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='162.99'
			height='19.609'
			viewBox='0 0 162.99 19.609'
			stroke='#d36f3b'

			{...props}
		>
			<path
				id='Scribble-qui-sommes-nous'
				d='M-20061.6-8300.279a427.214,427.214,0,0,1,76.186-10.392c43.313-2.068,70.385.789,70.385,8.348s-13.057,9.107-14.219,2.044,11.361-12.6,29.912-10.392'
				transform='translate(20062.094 8313.338)'
				fill='none'
				strokeWidth='4'
			/>
		</svg>
	)
}

export default MenuLine_Qui_Sommes_Nous_SVG
